import React, { useEffect, useState } from "react";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import Navbar from "../Navbar";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createAdsList } from "../../slice/ListSlice";
import { CategoryTreeChild, getCurrency } from "../../slice/CategorySlice";
import Swal from "sweetalert2";
import { getUserDetails } from "../../slice/AuthSlice";
import Subscription from "../Subscription";
import toast from "react-hot-toast";

function PostEvents() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { slug, id } = useParams();
  const initialFormState = {
    title: "",
    images: null,
    category: "",
    category_id: id,
    user_id: "",
    currency_id: 0,
    price: "",
    description: "",
  };

  const [formState, setFormState] = useState(initialFormState);
  const [activeInput, setActiveInput] = useState(null);
  const [screen, setScreen] = useState("form");
  const categoryAdsData = useSelector((store) => store.categories.catTreeChild);
  const SubCatPost = categoryAdsData?.data || [];

  const catMasterData = useSelector((store) => store.categories.currency);
  const CatMaster = catMasterData?.data || [];
  const userDetails = useSelector((store) => store.auth?.userDetail?.data);

  const handleInputFocus = (inputName) => {
    setActiveInput(inputName);
  };

  const handleInputBlur = () => {
    setActiveInput(null);
  };

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === "file") {
      setFormState({
        ...formState,
        [name]: files[0],
      });
    } else {
      setFormState({
        ...formState,
        [name]: value,
      });
    }
  };
  const handleFileChange = (event) => {
    const files = event.target.files;

    if (files) {
      const promises = Array.from(files).map((file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });
      });

      Promise.all(promises)
        .then((base64Strings) =>
          setFormState({ ...formState, images: base64Strings })
        )
        .catch((error) => console.error("Error converting files:", error));
    }
  };
  useEffect(() => {
    dispatch(getCurrency());
  }, []);
  useEffect(() => {
    if (id) {
      setFormState({ ...formState, category_id: parseInt(id) });
      dispatch(CategoryTreeChild({ id }));
    }
  }, [id]);
  useEffect(() => {
    if (!userDetails) {
      dispatch(getUserDetails());
    } else {
      if (userDetails.location === null) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "You must complete your profile first!",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/account?component=AccountInfo");
          }
        });
      }
    }
  }, [userDetails]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      formState.title === "" ||
      formState.category === "" ||
      formState.currency_id === 0 ||
      formState.price === "" ||
      formState.description === ""
    ) {
      alert("Please fill in all required fields.");
      return;
    }
    if (!formState.images) {
      alert("Please select either an image or a video, not both.");
      return;
    }
    if (parseFloat(formState.price) < 0) {
      alert("Price cannot be negative.");
      return;
    }
    setTimeout(() => {
      setScreen("pricing");
    }, 100);
    // // console.log(formState);
  };
  const onSubmit = async (item) => {
    const payload = {
      ...formState,
      location_id: userDetails.location.location_id,
      user_id: userDetails.customer_id,
      package: item,
      package_id: item.package_id,
      currency_id: parseInt(formState.currency_id),
    };
    console.log(payload);
    try {
      await dispatch(
        createAdsList({
          formData: payload,
        })
      ).unwrap();
      toast.success("Your post is created");
      navigate("/");
    } catch (error) {
      alert(error);
    }
  };
  return (
    <div>
      <Navbar />
      {screen === "form" && (
        <div
          className="w-full bg-cover bg-center"
          style={{ backgroundImage: `url(/img/form-bg-1.jpg)` }}
        >
          <div className="pt-32 lg:pt-20 w-full flex flex-col justify-center items-center gap-5 bg-black bg-opacity-50">
            <form
              className="w-11/12 md:w-6/12 bg-white p-4"
              onSubmit={handleSubmit}
            >
              <div className="full border border-slate-100 rounded-lg shadow-lg p-4">
                <div className="text-[#234777] text-4xl font-bold py-4">
                  {slug.toUpperCase()}
                </div>
                <div>
                  <hr></hr>
                </div>
                <div className="w-full">
                  <div className="py-2 text-[#234777] font-semibold">Title</div>
                  <div>
                    <input
                      type="text"
                      name="title"
                      value={formState.title}
                      placeholder="Type Your Product Title Here"
                      onFocus={() => handleInputFocus("title")}
                      onBlur={handleInputBlur}
                      onChange={handleChange}
                      className={`w-full p-2 py-2 border-b-2 border-gray-300 bg-slate-100 ${
                        activeInput === "title"
                          ? "border-b-2 border-blue-500"
                          : ""
                      }`}
                    />
                  </div>
                </div>
                <div className="w-full">
                  <div className="py-2 text-[#234777] font-semibold">
                    Add Image
                  </div>
                  <div>
                    <input
                      type="file"
                      multiple
                      accept="image/*"
                      name="images"
                      onChange={handleFileChange}
                      className="bg-slate-100 w-full p-2 py-2 border-b-2 border-gray-300"
                      placeholder="Type Your Product Title Here"
                    />
                  </div>
                </div>
                {/* <div className="w-full">
                  <div className="py-2 text-gray-500 font-semibold">
                    Add Video
                  </div>
                  <div className="bg-slate-200">
                    <input
                      type="file"
                      name="video"
                      onChange={handleChange}
                      className="bg-slate-100 w-full p-2 py-2 border-b-2 border-gray-300"
                      accept="video/*"
                    />
                  </div>
                </div> */}
                <div className="w-full">
                  <div className="w-full">
                    <div className="py-2 text-[#234777] font-semibold">
                      SUB CATEGORY
                    </div>
                    <div className="w-full">
                      <select
                        name="category"
                        value={formState.category}
                        onChange={handleChange}
                        className="w-full p-2 py-2 bg-slate-100 border-b-2 border-gray-300"
                      >
                        <option className="text-slate-600">
                          Select Sub Category
                        </option>
                        {SubCatPost.items &&
                          SubCatPost.items[0]?.childs?.map((subCat, i) => {
                            return (
                              <option key={i} value={subCat.name}>
                                {subCat.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="w-full gap-8 flex justify-between">
                  <div className="w-1/2">
                    <div className="py-2 text-[#234777] font-semibold">
                      Select Currency
                    </div>
                    <div className="w-full">
                      <select
                        name="currency_id"
                        value={formState.currency_id}
                        onChange={handleChange}
                        className="w-full p-2 py-2 bg-slate-100 border-b-2 border-gray-300"
                      >
                        <option className="text-slate-600">
                          Select Currency
                        </option>
                        {CatMaster.items &&
                          CatMaster.items.map((currency, i) => {
                            return (
                              <option key={i} value={currency.currency_id}>
                                {currency.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="w-1/2">
                    <div className="py-2 text-[#234777] font-semibold">
                      Price
                    </div>
                    <div>
                      <input
                        type="number"
                        name="price"
                        min="0"
                        value={formState.price}
                        onChange={handleChange}
                        className="w-full p-2 py-2 bg-slate-100 border-b-2 border-gray-300"
                        placeholder="Enter Your Pricing Amount"
                      />
                    </div>
                  </div>
                </div>
                <div className="w-full">
                  <div className="py-2 text-[#234777] font-semibold">
                    Ad Description
                  </div>
                  <div>
                    <textarea
                      name="description"
                      value={formState.description}
                      onChange={handleChange}
                      rows="4"
                      cols="50"
                      className="w-full p-4 bg-slate-100 border-b-2 border-gray-300"
                      placeholder="Describe Your Message"
                    />
                  </div>
                </div>
                <div className="w-full flex gap-10 py-5 text-center">
                  <div className="w-1/2 bg-blue-900 rounded-xl text-xs md:text-lg font-semibold py-2 text-white">
                    <button>
                      <BsFillArrowLeftCircleFill />
                    </button>
                  </div>
                  <div className="w-1/2 bg-blue-900 rounded-xl text-xs md:text-lg font-semibold py-2 text-white">
                    <button type="submit">SUBMIT</button>
                  </div>
                  {/* <div className="w-1/3 bg-blue-900 rounded-xl text-xs md:text-lg font-semibold py-2 text-white">
                  <button>PREVIEW</button>
                </div> */}
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      {screen === "pricing" && (
        <Subscription
          data={formState}
          postType={"ads"}
          onBack={() => setScreen("form")}
          onSubmit={(item) => {
            onSubmit(item);
          }}
        />
      )}
    </div>
  );
}

export default PostEvents;
