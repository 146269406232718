import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AffiliateServices from "../services/AffiliateServices";

const initialState = {
  loading: false,
  affiliateList: [],
  affiliateListTop: [],
  message: null,
  error: null,
};
export const getAffiliateList = createAsyncThunk(
  "aff/getAffiliateList",
  async ({ position, skip, limit }) => {
    const res = await AffiliateServices.getAffiliateList(position, skip, limit);
    return res.data;
  }
);
export const getAffiliateListTop = createAsyncThunk(
  "aff/getAffiliateListTop",
  async () => {
    const res = await AffiliateServices.getAffiliateListTop();
    return res.data;
  }
);
export const createAffiliate = createAsyncThunk(
  "aff/createAffiliate",
  async ({ formData }) => {
    const res = await AffiliateServices.createAffiliate(formData);
    return res.data;
  }
);
const handleError = (state, action) => {
  state.error = action.error.message;
};
const AffiliateSlice = createSlice({
  name: "aff",
  initialState,
  reducers: {
    redirectFalse: (state) => {
      state.redirect = false;
    },
    clearAffErrorAndMessage: (state) => {
      state.error = null;
      state.message = null;
    },
  },
  extraReducers: {
    [getAffiliateList.pending]: (state) => {
      state.loading = true;
    },
    [getAffiliateList.fulfilled]: (state, action) => {
      state.affiliateList = action.payload;
      state.loading = false;
    },
    [getAffiliateList.rejected]: handleError,

    //top
    [getAffiliateListTop.pending]: (state) => {
      state.loading = true;
    },
    [getAffiliateListTop.fulfilled]: (state, action) => {
      state.affiliateListTop = action.payload;
      state.loading = false;
    },
    [getAffiliateListTop.rejected]: handleError,

    [createAffiliate.pending]: (state) => {
      state.loading = true;
    },
    [createAffiliate.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [createAffiliate.rejected]: handleError,
  },
});
export const { clearAffErrorAndMessage, redirectFalse } =
  AffiliateSlice.actions;
const { reducer } = AffiliateSlice;
export default reducer;
